/* StyledInvoice.css */

.invoice-container {
  font-family: Arial, sans-serif;
  width: 80%;
  margin: auto;
  border: 2px solid #000;
  padding: 20px;
  background: #fff;
  display: flex;
    flex-direction: column;
    height: 100%;
}

.invoice-content {
  flex: 90%;
  overflow-y: auto;
  padding: 20px;
}

.signature-section {
  flex: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top: 2px solid black;
}

.signature-box {
  text-align: center;
  flex: 1;
}

.payment-info {
  text-align: center;
  font-size: 12px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.invoice-header img {
  height: 50px;
}

.invoice-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
}

/* Company Details */
.company-details {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}

/* Invoice Box Styling */
.invoice-details-box {
  border: 2px solid #7c7979;
  padding: 15px;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}

/* Section Title */
.section-title {
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 10px;
}

/* Customer Data */
.customer-data {
  font-size: 14px;
  margin: 5px 0;
}

/* Invoice Table */
.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.invoice-table td {
  border: 2px solid #000; /* Borders for both labels and values */
  padding: 10px;
  font-size: 14px;
}

/* Label Styling with Ash Background */
.table-header {
  background-color: #bebaba; /* Ash-colored background for labels */
  font-weight: bold;
  text-align: left;
}

.customer-info {
  text-align: left;
  width: 40%;
}

.company-info {
  text-align: left;
}

/* Bank Details Box */
.bank-details {
  border: 2px solid #000;
  padding: 10px;
  width: 50%;
  margin-top: 20px;
  font-weight: bold;
  background-color: #f9f9f9; /* Light gray background */
}

.task-table tr {
  border: none; /* Removes the border from table rows */
}

.task-table td {
  border-left: 2px solid #000000; /* Adds vertical borders */
  border-right: 2px solid #000000; /* Adds vertical borders */
  padding: 10px;
  font-size: 14px;
}

/* Ensures the top and bottom borders appear correctly */
.task-table tr:first-child td {
  border-top: 2px solid #000000;
}

.task-table tr:last-child td {
  border-bottom: 2px solid #000000;
}


.company-name {
  font-family: "Arial", sans-serif;
  letter-spacing: 1px;
  color: #D10000; /* Red, similar to Toyota */
}

.logo {
  border-radius: 5px; /* Optional: for rounded corners */
}

/* Signature Section */
.signature-section {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

@media print {
  /* StyledInvoice.css */
  body, html {
    background: #fff !important;
    color: #000 !important;
  }

  .invoice-container {
    font-family: Arial, sans-serif;
    width: 80%;
    margin: auto;
    border: 2px solid #000;
    padding: 20px;
    background: #fff;
    display: flex;
      flex-direction: column;
      height: 100%;
  }
  
  .invoice-content {
    flex: 90%;
    overflow-y: auto;
    padding: 20px;
  }
  
  .signature-section {
    flex: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-top: 2px solid black;
  }
  
  .signature-box {
    text-align: center;
    flex: 1;
  }
  
  .payment-info {
    text-align: center;
    font-size: 12px;
  }

.invoice-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.invoice-header img {
  height: 50px;
}

.invoice-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
}

/* Company Details */
.company-details {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}

/* Invoice Box Styling */
.invoice-details-box {
  border: 2px solid #7c7979;
  padding: 15px;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}

/* Section Title */
.section-title {
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 10px;
}

/* Customer Data */
.customer-data {
  font-size: 14px;
  margin: 5px 0;
}

/* Invoice Table */
.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.invoice-table td {
  border: 2px solid #000; /* Borders for both labels and values */
  padding: 10px;
  font-size: 14px;
}

.task-table tr {
  border: none; /* Removes the border from table rows */
}

.task-table td {
  border-left: 2px solid #000000; /* Adds vertical borders */
  border-right: 2px solid #000000; /* Adds vertical borders */
  padding: 10px;
  font-size: 14px;
}

/* Ensures the top and bottom borders appear correctly */
.task-table tr:first-child td {
  border-top: 2px solid #000000;
}

.task-table tr:last-child td {
  border-bottom: 2px solid #000000;
}

/* Label Styling with Ash Background */
.table-header {
  background-color: #bebaba; /* Ash-colored background for labels */
  font-weight: bold;
  text-align: left;
}

.customer-info {
  text-align: left;
  width: 40%;
}

.company-info {
  text-align: left;
}

/* Bank Details Box */
.bank-details {
  border: 2px solid #000;
  padding: 10px;
  width: 50%;
  margin-top: 20px;
  font-weight: bold;
  background-color: #f9f9f9; /* Light gray background */
}

.company-name {
  font-family: "Arial", sans-serif;
  letter-spacing: 1px;
  color: #D10000; /* Red, similar to Toyota */
}

.logo {
  border-radius: 5px; /* Optional: for rounded corners */
}

/* Signature Section */
.signature-section {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
}