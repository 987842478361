.invoice-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}
 
.invoice-header {
  text-align: center;
  margin-bottom: 20px;
}
 
.invoice-table1 {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 25px;
}
 
.invoice-table1 th, .invoice-table1 td {
  border-right: 2px solid #000000; /* Add right border for columns */
  padding: 8px;
  line-height: 0.8;
  text-transform: capitalize;
}

.invoice-table1 th {
  font-weight: bold;
}

.invoice-table1 tr {
  border-bottom: none; /* Remove horizontal lines between rows */
}

.invoice-table1 thead tr th {
  border-bottom: 2px solid #000000; /* Keep a border line for the header row if needed */
  border-top: 2px solid #000000;
  border-left: 2px solid #000000;
}

.invoice-table1 tbody tr td {
  border-bottom: 2px solid #ffffff; /* Keep a border line for the header row if needed */
  border-top: 2px solid #ffffff;
  border-left: 2px solid #000000;
}

.invoice-table2 {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 25px;
}
 
.invoice-table2 th, .invoice-table2 td {
  border-right: 2px solid #000000; /* Add right border for columns */
  padding: 8px;
  line-height: 0.8;
  text-transform: capitalize;
}

.invoice-table2 th {
  font-weight: bold;
}

.invoice-table2 tr {
  border-bottom: none; /* Remove horizontal lines between rows */
}

.invoice-table2 thead tr th {
  border-bottom: 2px solid #000000; /* Keep a border line for the header row if needed */
  border-top: 2px solid #000000;
  border-left: 2px solid #000000;
}

.invoice-table2 tbody tr td {
  border-bottom: 2px solid #ffffff; /* Keep a border line for the header row if needed */
  border-top: 2px solid #ffffff;
  border-left: 2px solid #000000;
}

.invoice-table3 {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 25px;
}
 
.invoice-table3 th, .invoice-table3 td {
  border-right: 2px solid #000000; 
  padding: 8px;
  line-height: 0.8;
  text-transform: capitalize;
}

.invoice-table3 th {
  font-weight: bold;
}

.invoice-table3 tr {
  border-bottom: none; 
}

.invoice-table3 thead tr th {
  border-bottom: 2px solid #000000; 
  border-top: 2px solid #000000;
  border-left: 2px solid #000000;
}

.invoice-table3 tbody tr td {
  border-bottom: 2px solid #ffffff; 
  border-top: 2px solid #ffffff;
  border-left: 2px solid #000000;
}

.customer-details {
  border: 1px solid #000; /* Border thickness and color */
  border-radius: 20px; /* Curve radius for the corners */
  margin-left: 47px; /* Space below the customer details section */
  background-color: #f9f9f9; /* Optional: Background color for the customer details */
}
 
.company-details {
  margin-left: 125px;
}

 
.signature-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding: 10px;
}
 
.authorized-signature,
.customer-signature {
  flex: 1;
}
 
.signature-line {
  margin-top: 20px;
  border-top: 1px solid #000;
  width: 100%;
}
 
.total-text {
  font-weight: bold;
}
 
.total-amount {
  font-weight: bold;
  text-align: right;
}
 
@media print {

  .signature-section {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100px; /* Fixed height for the signature section */
    border-top: 1px solid #ddd;
    padding: 10px;
    background-color: transparent; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    page-break-inside: avoid;
    margin-top: 25px; /* Adjusted margin to ensure space for content above */
  }

  /* Signature section contents */
  .signature-section p {
    width: 300px;
    text-align: center;
    font-size: 14px;
  }

  .signature-section .center-text {
    margin-top: 25px; /* Adjusted to align vertically in the center */
    font-size: 11px;
    font-weight: bold;
    text-align: center;
  }

  .signature-section span {
    display: inline-block;
    border-top: 1px solid black;
    width: 100%;
    padding-top: 5px;
    font-size: 14px;
    text-align: center;
  }

  /* Adjust content to avoid overlapping with the signature section */
  .invoice-table1 {
    width: 100%;
    page-break-inside: auto;
  }

  /* Allow rows to break across pages */
  .invoice-table1 tr {
    page-break-inside: auto; /* Allow rows to break across pages */
    page-break-after: auto;
  }

  .invoice-table2 {
    width: 100%;
    page-break-inside: auto;
  }

  /* Allow rows to break across pages */
  .invoice-table2 tr {
    page-break-inside: auto; /* Allow rows to break across pages */
    page-break-after: auto;
  }

  .invoice-table3 {
    width: 100%;
    page-break-inside: auto;
  }

  /* Allow rows to break across pages */
  .invoice-table3 tr {
    page-break-inside: auto; /* Allow rows to break across pages */
    page-break-after: auto;
  }
}